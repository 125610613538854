import React from "react";
import { Segment, Container, Icon } from "semantic-ui-react";
import ReactTags from 'react-tag-autocomplete'
import moment from "moment";
import {
  fetchImages,
  fetchTags,
  fetchArtists,
  updateImageStatus,
  deleteImage,
  fetchCurations,
  fetchUser,
  saveClaimApproved
} from "../../modules/api";
import Photo from "../elements/photo";
import MainPhoto from "../elements/main-photo";
import Paginator from "../elements/paginator";
import config from "../../config/config";
import { hasBodyClass, mapArtist, notEmptyString, removeBodyClass, sanitize } from "../../modules/utils";

class Gallery extends React.Component {
  mounted = false;
  loadTimeout = null;

  state = {
    title: "Gallery",
    images: [],
    numImages: 0,
    tags: [],
    numTags: 0,
    artists: [],
    numArtists: 0,
    numCols: 6,
    main: [],
    currMainIndex: -1,
    showMain: false,
    selectedTags: [],
    activeFilter: "",
    activeStatus: "all",
    searchTerm: "",
    filterType: "",
    statusOpts: [],
    message: "",
    showMessage: false,
    totalImages: 0,
    loadOffset: 0,
    page: 0,
    numPages: 1,
    currUser: null,
    mayAddToCurations: false,
    curations: [],
    numCurations: [],
    loading: false,
    escaped: false,
    claimToggleMsg: ''
  };

  constructor(props) {
    super(props);
    this.rTags = React.createRef();
  }

  async componentWillMount() {
    this.parseParams();
    let user = await fetchUser();
    if (user) {
      if (user.role) {
        let mayAddToCurations = false;
        switch (user.role) {
          case "admin":
          case "selector":
            mayAddToCurations = true;
            break;
        }
        this.setState({
          currUser: user,
          mayAddToCurations: mayAddToCurations
        });
      }
    }
  }

  async componentDidMount() {
    if (!this.mounted) {
      let pn = this.matchPage();
      this.setState({ page: pn });
      this.loadTimeout = setTimeout(() => this.loadImages(), 125);
      let tagData = await fetchTags();
      this.setState({
        tags: tagData.items,
        numTags: tagData.num
      });
      let aData = await fetchArtists();
      this.setState({
        artists: aData.items,
        numArtists: aData.num
      });
      this.handleStateOpts();
      window.addEventListener("keydown", this.handleKeyDown);

      this.mounted = true;
      if (this.state.mayAddToCurations) {
        setTimeout(() => {
          fetchCurations('all').then(data => {
            if (data.items instanceof Array) {
              const items = data.items.filter(item => item instanceof Object);
              const toModeSort = (mode) => mode === 'folder' ? -1 : 1;
              const mapCurationItem = (item, index) => {
                const md = notEmptyString(item.mode) ? item.mode : 'folder';
                const key = ['cur-item-selector', item._id, index].join('-');
                return { ...item, key, className: md }
              }
              items.sort((a, b) => toModeSort(a.mode) - toModeSort(b.mode))
              this.setState({
                curations: items.map(mapCurationItem),
                numCurations: items.length
              });
            }
          });
        }, 500);
      }
      this.loadSelectedImage();
    }
  }

  matchPage = () => {
    let { location } = this.props;
    let pn = 0;
    if (location.search) {
      let strPn = location.search
        .split("page=")
        .pop()
        .split("&")
        .shift();
      if (strPn) {
        pn = parseInt(strPn);
      }
    }
    return pn;
  };

  componentDidUpdate(prevProps, prevState) {
    setTimeout(() => {
      const { activeStatus, loading } = this.state;
      const pn = this.matchPage();
      const prevStatus = prevState.activeStatus;
      if ((prevStatus !== activeStatus || pn !== prevState.page) && !loading) {
        this.setState({ page: pn });
        this.loadImages(false);
      }
    }, 125);
  }

  componentWillUnmount() {
    window.removeEventListener("keydown", this.handleKeyDown);
    if (this.loadTimeout) {
      clearTimeout(this.loadTimeout);
    }
  }

  parseParams = () => {
    if (this.props.location) {
      if (this.props.location.pathname) {
        let parts = this.props.location.pathname.split("/images/");
        let status = "all";
        let category = "all";
        if (parts.length > 1) {
          parts = parts.pop().split("/");
          if (parts.length > 0) {
            status = parts.shift();
            if (parts.length > 0) {
              category = parts.shift();
            }
          }
        }
        this.setState({
          activeFilter: category,
          activeStatus: status
        });
      }
    }
  };

  updateImageData = (edited, user) => {
    if (edited._id) {
      let keys = Object.keys(edited);
      let imgs = this.state.images;
      let img = imgs.find(im => im._id === edited._id);
      if (img) {
        for (let i = 0; i < keys.length; i++) {
          let k = keys[i];
          switch (k) {
            case "caption":
            case "description":
            case "location":
            case "embed":
            case "topic":
              img[k] = edited[k];
              break;
            case "urls":
              if (edited[k] instanceof Array) {
                img.urls = edited[k];
              }
              break;
            case "artist":
              if (edited[k] instanceof Object) {
                img.artist = edited[k];
              }
              break;
            case "tags":
              if (edited[k] instanceof Array) {
                let allTs = this.state.tags;
                let ts = edited[k].map((tn, ti) => {
                  const tg = allTs.find(
                    t => {
                      if (t instanceof Object && typeof tn === 'string') {
                        const { name, text } = t;
                        const refName = notEmptyString(name) ? name : notEmptyString(text) ? text : '';
                        return refName.toLowerCase() === tn.toLowerCase()
                      } else {
                        return false;
                      }
                    });
                  if (tg) {
                    return tg;
                  } else {
                    return {
                      _id: "i_" + ti.toString(),
                      name: tn
                    };
                  }
                });
                img[k] = ts;
              }
              break;
            case "user":
              img.user = user;
              break;
          }
        }
        img = this.mapImage(img);
        let editedImgs = imgs.map(im => {
          if (im._id === img._id) {
            return img;
          } else {
            return im;
          }
        });
        this.setState({
          images: editedImgs
        });
        setTimeout(() => {
          this.updateTags();
        }, 750);
      }
    }
  };

  handleKeyDown = e => {
    switch (e.which) {
      case 39:
      case 37:
        if (!e.metaKey && !e.shiftKey && this.state.showMain) {
          let tn = e.target.tagName.toLowerCase();
          switch (tn) {
            case "input":
            case "textarea":
              break;
            default:
              if (e.which === 39) {
                if (this.state.currMainIndex < this.state.numImages - 1) {
                  this.showNext();
                }
              } else {
                if (this.state.currMainIndex > 0) {
                  this.showPrev();
                }
              }
              break;
          }
        }
        break;
      case 27:
        if (this.state.showMain) {
          this.unsetMain();
        }
        break;
    }
  };

  loadSelectedImage = () => {
    if (window.location.hash.length > 5) {
      if (window.location.hash.indexOf('#id--') === 0) {
        let imgId = window.location.hash.split('--').pop();
        let selImg = this.state.images.find(img => img._id.toString() === imgId);
        if (selImg) {
          this.setMain(selImg);
        }
      }
    }
  };

  editStatus = async (id, status) => {
    let data = {
      valid: false
    };
    if (id) {
      switch (status) {
        case "approved":
        case "referred":
        case "rejected":
        case "promote":
        case "unpromote":
          data = await updateImageStatus(id, status);
          break;
        case "delete":
          let img = this.state.images.find(im => im._id === id);
          if (img) {
            this.deleteImage(img);
          }
          break;
      }
    }
    if (data.valid) {
      let imgs = this.state.images.map(img => {
        if (img._id === data._id) {
          if (['promote', 'unpromote'].includes(status)) {
            img.highlighted = status === 'promote';
          } else {
            img.status = status;
          }
        }
        return this.mapImage(img);
      });
      this.setState({
        images: imgs
      });
    }
  };

  deleteImage = async img => {

    if (img instanceof Object) {
      deleteImage(img._id).then(res => {
        if (res.valid) {
          this.setState({
            message: `Image (${img.caption}) removed`,
            showMessage: true
          });
          setTimeout(() => this.removeImage(img), 1500);
        }
      });
    }
  };

  removeImage = img => {
    let imgs = this.state.images.filter(im => im._id !== img._id);
    this.setState({
      images: imgs,
      numImages: imgs.length,
      hasSelection: false,
      showMessage: false
    });
    this.unsetMain();
  };

  loadImages = async init => {
    let { activeStatus, activeFilter, loading, searchTerm } = this.state;
    let total = 0;
    let perLoad = config.gallery.perLoad;
    let loadOffset = init === true ? 0 : this.state.page * perLoad;
    if (!activeStatus) {
      activeStatus = "all";
    }
    if (!activeFilter) {
      activeFilter = "all";
    }
    if (!loading) {
      this.setState({ images: [], numImages: 0, loading: true });
      let term = '';
      if (notEmptyString(searchTerm)) {
        loadOffset = 0;
        activeFilter = "all";
        term = searchTerm;
      }
      const data = await fetchImages(
        activeStatus,
        loadOffset,
        perLoad,
        activeFilter,
        term
      );
      if (data.filterType) {
        const { filterType } = data;
        this.setState({filterType});
      }
      const imgs = data.items
        .filter(img => img.hasPublicId || img.uri.includes('contrib-images'))
        .map(img => this.mapImage(img));
      if (data.total) {
        total = data.total;
      }
      this.setState({
        images: imgs,
        numImages: imgs.length,
        total: total,
        numPages: data.pages
      });
      setTimeout(() => {
        this.setState({ loading: false });
      }, 500)
      setTimeout(() => {
        this.updateTags();
      }, 3000); 
    }
  };

  mainImageClassNames = (index, defIndex) => {
    let cls = index === defIndex ? ["active"] : ["inactive"];
    if (index === defIndex - 1) {
      cls.push("prev");
    } else if (index === defIndex + 1) {
      cls.push("next");
    } else if (index !== defIndex) {
      cls.push("off");
    }
    return cls.join(" ");
  };

  setMain = async (targetImg, editMode = false) => {
    let index = this.state.images.findIndex(im => im._id === targetImg._id);
    if (index >= 0) {
      targetImg.editMode = editMode === true;
      let startIndex = index > 0 && this.state.numImages > 1 ? index - 1 : 0;
      let numMainImgs = this.state.numImages > 2 ? 3 : this.state.numImages;
      let endIndex = startIndex + numMainImgs - 1;
      let lastIndex = this.state.numImages - 1;
      if (endIndex > lastIndex) {
        let diff = endIndex - lastIndex;
        endIndex = lastIndex;
        startIndex -= diff;
      }
      let imgs = [];
      for (let i = startIndex; i <= endIndex; i++) {
        imgs.push(this.state.images[i]);
      }
      let defIndex = 1;

      if (index === 0) {
        defIndex = 0;
      } else if (index === lastIndex) {
        defIndex = numMainImgs - 1;
      }
      imgs = imgs.map((img, si) => {
        img.className = this.mainImageClassNames(si, defIndex);
        return img;
      });
      this.setState({
        main: imgs,
        showMain: true,
        currMainIndex: index
      });
    }
  };

  mapImage = img => {
    
    switch (img.status) {
      case "rejected":
        img.mayDelete = true;
        break;
      default:
        img.mayDelete = false;
        break;
    }
    switch (img.status) {
      case "approved":
        img.statusClassNames = "thumbs up";
        break;
      case "referred":
        img.statusClassNames = "hand point right";
        break;
      case "rejected":
        img.statusClassNames = "thumbs down";
        break;
      default:
        img.statusClassNames = "circle notched";
        break;
    }
    img.statusClassNames += " status-icon";

    img.hasLocation = false;
    if (img.location) {
      if (img.location.placename) {
        img.hasLocation = true;
      }
    }
    img.hasDescription = false;
    if (img.description) {
      if (typeof img.description === "string") {
        img.hasDescription = img.description.length > 3;
      }
    }
    img.selected = false;
    img.artist = mapArtist(img.artist);
    img.hasArtist = notEmptyString(img.artist.name)
    return img;
  };

  addToMain = (index, dir) => {
    if (index >= 0 && index < this.state.images.length) {
      let img = this.state.images[index];
      let imgs = this.state.main;
      img.className = "inactive off";
      if (dir > 0) {
        imgs.push(img);
      } else {
        imgs.unshift(img);
      }
      this.setState({
        main: imgs
      });
    }
  };

  unsetMain = () => {
    if (hasBodyClass('full-mode')) {
      removeBodyClass('full-mode')
      this.setState({
        escaped: true
      });
      setTimeout(() => {
        this.setState({
          escaped: false
        });
      }, 1000)
    } else {
      this.setState({
        showMain: false,
        currMainIndex: -1,
      });
    }
    this.updateTags();
  };

  updateTags = () => {
    const { images, tags } = this.state;
    
    if (images instanceof Array && tags instanceof Array) {
      const numTags = tags.length;
      images.forEach((image) => {
        const { tags } = image;
        tags.forEach(tg => {
          const tgIndex = tags.findIndex(t2 => t2._id.toString() === tg._id.toString());
          if (tgIndex < 0) {
            tags.push({ ...tg, text: tg.name, slug: sanitize(tg.name), num: 1 });
          }
        })
      })
      if (tags.length > numTags) {
        this.setState({ tags });
      }
    }
  }

  showNext = prev => {
    let dir = prev === true ? -1 : 1;
    const lastIndex = this.state.main.length - 1;
    const currIndex = this.state.main.findIndex(
      img => img.className === "active"
    );
    const currImg = this.state.main[currIndex];
    const targetIndex = currIndex + dir;
    if (currImg && this.state.main.length > 0) {
      if (lastIndex >= 0) {
        const imgs = this.state.main.map((img, si) => {
          img.className = this.mainImageClassNames(si, targetIndex);
          return img;
        });
        this.setState({
          showMain: imgs,
          currMainIndex: this.state.currMainIndex + dir
        });
        let endImg = dir > 0 ? this.state.main[lastIndex] : this.state.main[0];
        let nextIndex =
          this.state.images.findIndex(img => img._id === endImg._id) + dir;
        setTimeout(() => {
          this.addToMain(nextIndex, dir);
        }, 300);
      }
    }
  };

  showPrev = () => {
    this.showNext(true);
  };

  showNextIcon = () => {
    return this.state.currMainIndex < this.state.numImages - 1;
  };

  showPrevIcon = () => {
    return this.state.currMainIndex > 0;
  };

  handleStateOpts = () => {
    const opts = ["uploaded", "referred", "rejected", "unapproved", "approved", "claimed", "unaccepted", "accepted", "all"];
    let stOpts = opts.map(key => {
      let name = '';
      switch (key) {
        case 'uploaded':
          name = 'new';
          break;
        case 'claimed':
          name = 'all claims';
            break;
        case 'accepted':
          name = 'approved claims';
          break;
        case 'unaccepted':
          name = 'unapproved claims';
          break;
        case 'unapproved':
          name = 'all unapproved';
          break;
        default:
          name = key;
          break;
      }
      return {
        key: key,
        name: name,
        className: key === this.state.activeStatus ? "active" : "inactive"
      };
    });
    this.setState({
      statusOpts: stOpts
    });
  };

  updateUrl = () => {
    let { activeStatus, activeFilter } = this.state;
    if (!activeFilter) {
      activeFilter = "all";
    } if (!activeStatus) {
      activeStatus = "all";
    }
    let path = `/images/${activeStatus}/${activeFilter}`;
    this.props.history.push(path);
  };

  filterByTag(tag) {
    if (typeof tag === "string") {
      tag = {
        slug: "all",
        name: "All"
      };
    }
    if (tag.slug !== this.state.activeFilter) {
      this.setState({
        activeFilter: tag.slug,
        filterName: tag.name,
        loadOffset: 0
      });
      setTimeout(() => this.updateUrl(), 50);
      setTimeout(() => this.loadImages(), 100);
    } else {
      this.setState({
        loadOffset: 0
      });
      setTimeout(() => this.loadImages(), 75);
    }
  }

  filterByUser(user) {
    let ref = "u--" + user._id;
    if (ref !== this.state.activeFilter) {
      this.setState({
        activeFilter: ref,
        filterName: user.displayName
      });
      setTimeout(() => this.updateUrl(), 50);
      setTimeout(() => this.loadImages(), 100);
    }
  }

  filterByStatus = status => {
    if (status !== this.state.activeStatus) {
      this.setState({
        activeStatus: status
      });
      setTimeout(() => {
        this.updateUrl();
      }, 100);
    }
  };

  updateStatusFilter = e => {
    this.filterByStatus(e.target.value)
  };

  selectTag = row => {
    if (row !== null && typeof row === "object") {
      const { id } = row;
      const { artists, tags } = this.state;
      let tag = tags.find(tg => tg._id === id);
      const isTag = tag instanceof Object;
      let isArtist = false;
      if (!isTag) {
        tag = artists.find(a => a._id === id);
        isArtist = tag instanceof Object;
      }
      const matched = isTag || isArtist;
      if (isTag) {
        this.filterByTag(tag);
      } else if (isArtist) {
        this.filterByUser(tag);
      }
      if (matched) {
        this.setState({
          selectedTags: [row]
        });
      }
    }
  };

  deleteTag = e => {
    let valid = true;
    if (e) {
      if (e.target) {
        valid = false;
        if (e.target.classList.contains("ReactTags__tag")) {
          valid = true;
        }
      }
    }
    if (valid) {
      this.filterByTag("all");
      this.setState({
        selectedTags: []
      });
    }
  };

  handleSuggestions = (txt, suggestions) => {
    let lcTxt = txt.toLowerCase();
    return suggestions.filter(suggestion => {
      switch (suggestion.type) {
        case "tag":
          return suggestion.name.toLowerCase().includes(lcTxt);
        default:
          let matched = suggestion.name.toLowerCase().includes(lcTxt);
          if (!matched) {
            if (suggestion.email) {
              matched = suggestion.email.toLowerCase().includes(lcTxt);
            }
          }
          return matched;
      }
    });
  };

  selectedClassNames = img => {
    let cls = ["square", "outline"];
    if (img.selected) {
      cls.unshift("check");
    }
    return cls.join(" ");
  }

  selectAll = newMode => {
    let imgs = this.state.images.map(im => {
      im.selected = newMode;
      return im;
    });
    this.setState({ images: imgs });
  }

  bulkApprove = () => {
    this.bulkUpdate("approved");
  }

  bulkReject = () => {
    this.bulkUpdate("rejected");
  }

  bulkStar = () => {
    this.bulkUpdate("promote");
  }

  bulkUnstar = () => {
    this.bulkUpdate("unpromote");
  }

  bulkUpdate = status => {
    const ids = this.state.images.filter(im => im.selected).map(im => im._id);
    updateImageStatus(ids, status).then(d => {
      if (d.valid) {
        const imgs = this.state.images.map(im => {
          if (ids.indexOf(im._id) >= 0) {
            switch (status) {
              case "promote":
                im.highlighted = true
                break
              case "unpromote":
                im.highlighted = false
                break
              default:
                im.status = status
                break
            }
            im.selected = false
          }
          return this.mapImage(im);
        });
        this.setState({ images: imgs });
        this.forceUpdate();
      }
    });
  };

  selectPhoto = img => {
    img.selected = img.selected !== true;
    this.forceUpdate();
  };

  combineTags = () => {
    const { artists, tags } = this.state;
    return artists.filter(a => a.numImages > 0).concat(tags).map(item => {
      let txt = "";
      let name = "";
      let sType = "";
      let email = "";
      if (item.displayName) {
        sType = "artist";
        txt = `${item.displayName}, ${item.identifier}`;
        name = item.displayName;
        email = item.identifier;
      } else if (item.name) {
        sType = "tag";
        txt = `${item.name} [${sType}]`;
        name = item.name;
      }
      if (sType) {
      }
      txt += " (" + item.numImages + ")";
      const slug = item.slug ? item.slug : item._id;

      return {
        id: item._id,
        text: txt,
        slug: slug,
        name: name,
        type: sType,
        email: email
      };
    });
  };

  selectedStats = () => {
    let selected = this.state.images.filter(im => im.selected);
    let numApproved = 0;
    let total = 0;
    if (selected instanceof Array && selected.length > 0) {
      numApproved = selected.filter(im => im.status === "approved").length;
      total = selected.length;
    }
    return {
      total: total,
      approved: numApproved,
      unapproved: total - numApproved
    };
  };

  checkTextSearch = (text = '') => {
    if (notEmptyString(text)) {
      this.setState({searchTerm: text})
    } else {
      this.setState({searchTerm: ''});
    }
  }

  searchByText = () => {
    this.loadImages();
  }

  clearText = () => {
    this.setState({searchTerm: ''});
    if (this.rTags) {
      const { current } = this.rTags;
      if (current instanceof ReactTags) {
        current.state.query = '';
      }
    }
    setTimeout(() => {
      this.loadImages();
    }, 375);
  }

  /* updateDayFilter = e => {
    let update = true
    let sd = 'all'
    if (e.target.value) {
      sd = e.target.value
      if (/^20\d\d-\d\d-\d\d/.test(sd)) {
        update = true
      } else if (sd.length < 1) {
        update = true
        sd = 'all'
      } else {
        update = false
      }
    }
    if (update) {
      this.setState({
        activeFilter: sd,
        activeStatus: 'all'
      });
      setTimeout(() => this.loadImages(), 100);
    }
  } */

  buildImageClassNames(img) {
    const { className, status, highlighted } = img;
    const cls = [status];
    if (className) {
      cls.unshift(className);
    }
    if (highlighted) {
      cls.push('promoted');
    }
    return cls.join(' ');
  }

  buildWrapperClasses(img) {
    const { status, artist } = img;
    const cls = [status];
    if (artist instanceof Object) {
      if (notEmptyString(artist.name)) {
        cls.push("is-claimed");
        if (artist.approved === true) {
          cls.push("claim-approved");
        }
      }
    }
    return cls.join(' ');
  }

  buildContextSlideTitle(img) {
    const { status, artist } = img;
    const parts = [status.replace(/_/, ' ')];
    if (artist instanceof Object) {
      if (notEmptyString(artist.name)) {
        if (artist.approved) {
          parts.push('claim accepted');
        } else {
          parts.push('claimed pending approval');
        }
      }
    }
    return parts.join(", ");
  }

  toggleClaimApproved = (img) => {
    const _id = img instanceof Object ? img._id : '';
    if (notEmptyString(_id, 12)) {
      const { artist } = img;
      if (artist instanceof Object) {
        const newStatus = artist.approved !== true;
        saveClaimApproved(_id, newStatus).then(result => {
          if (result) {
            const { artist } = img;
            if (artist instanceof Object) {
              artist.approved = result.status;
              this.updateImageData({_id, artist });
              const claimToggleMsg = result.status ? 'Claim approved' : 'Claim approval removed';
              this.setState({claimToggleMsg })
              setTimeout(() => {
                this.loadImages(false);
                this.setState({claimToggleMsg: '' })
              }, 2000);
            }
          }
        })
      }
    }
  }

  claimedToggleMessage = (img) => {
    if (img instanceof Object) {
      const { artist } = img;
      if (artist instanceof Object) {
        return artist.approved === true ? 'Remove claim approval' : 'Approve claim';
      }
    }
    return '';
  }

/*   resetDay = () => {
    this.setState({
      activeFilter: 'all'
    });
    setTimeout(() => this.loadImages(), 100);
  } */

  updateCurations = (curId, imgId, diff) => {
    let { curations } = this.state;
    let cur = curations.find(c => c._id === curId);
    if (cur) {
      if (diff > 0) {
        cur.images.push({
          image: imgId,
          selected: false
        });
      } else if (diff < 0) {
        cur.images = cur.images.filter(ims => ims.image.toString() !== imgId);
      }
      this.setState({ curations: curations });
    }
  };

  render() {
    const {
      title,
      images,
      numImages,
      activeFilter,
      activeStatus,
      main,
      showMain,
      statusOpts,
      message,
      showMessage,
      tags,
      total,
      page,
      numPages,
      mayAddToCurations,
      curations,
      escaped,
      filterType,
      claimToggleMsg
    } = this.state;
    let { selectedTags } = this.state;
    const mixedTags = this.combineTags();
    let numSelected = images.filter(im => im.selected).length;
    let selectAllClassName = "square outline";
    let bulkSelect = numSelected < numImages / 2;
    if (bulkSelect) {
      selectAllClassName += " check";
    }
    let selectedStats = this.selectedStats();
    let showApproveSelected =
      selectedStats.unapproved > 0 && activeStatus !== "approved";
    let showRejectSelected =
      selectedStats.approved > 0 && selectedStats.total < numImages / 2;

    let cls = ["top-bar"];
    if (!activeFilter) {
      activeFilter = "all";
    }
    if (activeFilter !== "all") {
      cls.push("is-filtered");
    }
    if (activeFilter) {
      if (activeFilter !== "all") {
        const slug = activeFilter.split("--").pop();
        let tag = mixedTags.find(t => t.slug === slug);
        if (tag) {
          selectedTags = [tag];
        }
      }
    }
    let topBarClassNames = cls.join(" ");
    cls = ["contributor-images"];
    if (showMain) {
      cls.push("show-main");
    } else {
      cls.push("thumbnails");
    }
    if (showMessage) {
      cls.push("show-message");
    }
    const mainClassNames = cls.join(" ");
    const showPaginator = numPages > 1;
    const rTagsClassNames = { searchWrapper: "textfield textfield-long" };
    const showStarred = images.some(img => img.selected && img.status === 'approved' && !img.highlighted);
    const showUnstarred = images.some(img => img.selected && img.highlighted);
    const hasTextFilter = filterType === 'text';
    const showClaimToggleMsg = notEmptyString(claimToggleMsg, 3);
    return (
      <Container className={mainClassNames}>
        <div className={topBarClassNames}>
          <h1 onClick={() => this.filterByTag("all")}>
            <span className="title">{title}</span>
            <Icon className="refresh" />
            <em className="num-images">
              {numImages} of {total}
            </em>
          </h1>
          <ReactTags
            ref={this.rTags}
            tags={selectedTags}
            suggestions={mixedTags}
            classNames={rTagsClassNames}
            onDelete={this.deleteTag}
            onAddition={this.selectTag}
            onInput={this.checkTextSearch}
            placeholderText="Search"
            inline={false}
          />
          {hasTextFilter && <Icon className="close icon clear-search inline-block" onClick={this.clearText} />}
          <Icon className="search search-submit inline-block" onClick={this.searchByText} />
          {statusOpts.length > 0 && (
            <select
              name="status_options"
              value={activeStatus}
              onChange={this.updateStatusFilter}
            >
              {statusOpts.map(opt => (
                <option value={opt.key} key={opt.key}>
                  {opt.name}
                </option>
              ))}
            </select>
          )}
          <div className="bulk-actions">
            <span className="num-selected">{selectedStats.total}</span>
            {showApproveSelected && (
              <Icon
                className="thumbs up on-state"
                title="Approve all selected images"
                onClick={this.bulkApprove}
              />
            )}
            {showRejectSelected && (
              <Icon
                className="thumbs down off-state"
                title="Reject all selected images"
                onClick={this.bulkReject}
              />
            )}
            {showStarred && (
              <Icon
                className="star on-state"
                title="Promote selected images"
                onClick={this.bulkStar}
              />
            )}
            {showUnstarred && (
              <Icon
                className="star outline off-state"
                title="Unstar selected images."
                onClick={this.bulkUnstar}
              />
            )}
            <Icon
              className={selectAllClassName}
              onClick={() => this.selectAll(bulkSelect)}
            />
            <a href="/api/admin/works-csv/approved" title="Download all approved images as a CSV">
              <Icon
                className="file excel outline"
              />
              <span className="label">CSV</span>
            </a>
          </div>
        </div>
        <div className="message-pane">{message}</div>
        <Segment.Group id="image-list" horizontal>
          {numImages > 0 &&
            images.map((img, index) => (
              <Segment as="figure" key={img._id} className={this.buildWrapperClasses(img)} title={this.buildContextSlideTitle(img)}>
                <Photo
                  img={img}
                  width={400}
                  height={2000}
                  crop="fill"
                  className={this.buildImageClassNames(img)}
                />
                <div
                  className="photo-overlay"
                  onClick={() => this.setMain(img)}
                />
                <Icon
                  className={this.selectedClassNames(img)}
                  onClick={() => this.selectPhoto(img)}
                />
                {img.highlighted && <Icon
                  className="star"
                  title="Select to rate this image"
                />}
                <Icon className={img.statusClassNames} title={img.status} />
                <figcaption onClick={() => this.setMain(img, true)}>
                  <p className="caption">{img.caption}</p>
                  <p className="full-name">{img.user.displayName}</p>
                  <p className="created datetime">{moment(img.created).format("DD/MM/YYYY HH:mm")}</p>
                </figcaption>

                {img.hasArtist && <>
                  <div className="claimed-by">
                    <div className="claimed-name" title={this.claimedToggleMessage(img)}  onClick={() => this.toggleClaimApproved(img)}>{img.artist.name}</div>
                    {notEmptyString(img.artist.caption) && <p className="claimed-caption" title="caption notes">{img.artist.caption}</p>}
                  </div>
                  
                </>}
              </Segment>
            ))}
        </Segment.Group>
        {showMain && (
          <Segment.Group className="main-images">
            <Icon className="close" onClick={this.unsetMain} />
            {this.showPrevIcon() && (
              <Icon className="arrow left" onClick={this.showPrev} />
            )}
            {main.map((mainImg, si) => (
              <MainPhoto
                img={mainImg}
                sizes={config.imageStyles.large.sizes}
                xlSizes={config.imageStyles.xlarge.sizes}
                key={'main_' + mainImg._id}
                className={this.buildImageClassNames(mainImg)}
                editStatus={this.editStatus}
                tags={tags}
                updateImageData={this.updateImageData.bind(this)}
                curations={curations}
                mayAddToCurations={mayAddToCurations}
                updateCurations={this.updateCurations}
                escaped={escaped}
              />
            ))}
            {this.showNextIcon() && (
              <Icon className="arrow right" onClick={this.showNext} />
            )}
          </Segment.Group>
        )}
        <div className="backdrop" onClick={this.unsetMain} />
        {showPaginator && (
          <Paginator linkPath="/images" numPages={numPages} page={page} />
        )}
        {showClaimToggleMsg && <p className="status-message">{claimToggleMsg}</p>}
      </Container>
    );
  }
}

export default Gallery;
